* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'PT Sans', sans-serif;
}

.hero {
   background: url(../../assets/gifs/113180-types-of-events.gif) center/contain no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   position: relative;
}

.get-app {
   position: absolute;
   top: 0;
   right: 0;
   padding: 1rem 2rem;
   display: flex;
   justify-content: center;
}