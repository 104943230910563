@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* .header {
  text-align: center;
} */
.card-counter{
        box-shadow: 2px 2px 10px #DADADA;
        margin: 5px;
        padding: 20px 10px;
        background-color: #fff;
        height: 100px;
        border-radius: 5px;
        transition: .3s linear all;
      }
    
      .card-counter:hover{
        box-shadow: 4px 4px 20px #DADADA;
        transition: .3s linear all;
      }
    
      .card-counter.primary{
        background-color: #007bff;
        color: #FFF;
      }
    
      .card-counter.danger{
        background-color: #ef5350;
        color: #FFF;
      }  
    
      .card-counter.success{
        background-color: #66bb6a;
        color: #FFF;
      }  
    
      .card-counter.info{
        background-color: #26c6da;
        color: #FFF;
      }  
    
      .card-counter i{
        font-size: 5em;
        opacity: 0.2;
      }
    
      .card-counter .count-numbers{
        position: absolute;
        right: 35px;
        top: 20px;
        font-size: 32px;
        display: block;
        color: #ffffff;
      }
    
      .card-counter .count-name{
        position: absolute;
        right: 35px;
        top: 65px;
        font-style: italic;
        text-transform: capitalize;
        opacity: 0.5;
        display: block;
        font-size: 18px;
      }
.form-container {
        margin: 100px auto;
        width: 1000px;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 10px;
        height: 600px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      
      .close-btn {
        position: absolute;
        top: 2%;
        right: 3%;
        font-size: 1.5rem;
        z-index: 1;
        color: #fff;
        cursor: pointer;
      }
      
      .form-content-left {
        background: linear-gradient(
          90deg,
          rgb(39, 176, 255) 0%,
          rgb(12, 95, 228) 100%
        );
        border-radius: 10px 0 0 10px;
        position: relative;
      }
      
      .form-img {
        width: 80%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
      .form-img-2 {
        width: 60%;
        height: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
      .form-success {
        text-align: center;
        font-size: 24px;
        margin-top: 80px;
        color: #fff;
      }
      
      .form-content-right {
        border-radius: 0 10px 10px 0;
        position: relative;
        background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
      }
      
      .form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .form h1 {
        font-size: 1rem;
        text-align: start;
        width: 80%;
        margin-bottom: 1rem;
        color: #fff;
      }
      
      .form-inputs {
        margin-bottom: 0.5rem;
        width: 80%;
      }
      
      .form-inputs p {
        font-size: 0.8rem;
        margin-top: 0.5rem;
        color: #f00e0e;
      }
      
      .form-label {
        display: inline-block;
        font-size: 0.8rem;
        margin-bottom: 6px;
        color: #fff;
      }
      
      .form-input {
        display: block;
        padding-left: 10px;
        outline: none;
        border-radius: 2px;
        height: 40px;
        width: 100%;
        border: none;
      }
      
      .form-input::placeholder {
        color: #595959;
        font-size: 12px;
      }
      
      .form-input-btn {
        width: 80%;
        height: 50px;
        margin-top: 10px;
        border-radius: 2px;
        background: linear-gradient(
          90deg,
          rgb(72, 102, 223) 0%,
          rgb(12, 95, 228) 100%
        );
        outline: none;
        border: none;
        color: #fff;
        font-size: 1rem;
      }
      
      .form-input-btn:hover {
        cursor: pointer;
        background: linear-gradient(
          90deg,
          rgb(39, 143, 255) 0%,
          rgb(12, 99, 250) 100%
        );
        transition: all 0.4s ease-out;
      }
      
      .form-input-login {
        font-size: 0.8rem;
        margin-top: 10px;
        color: #fff;
        width: 80%;
        text-align: center;
      }
      
      .form-input-login a {
        text-decoration: none;
        color: #27cdff;
        font-weight: 600;
      }
/* Hide scrollbar for Chrome, Safari and Opera */
.removeScollBar::-webkit-scrollbar {
        display: none;
 }
      
/* Hide scrollbar for IE, Edge and Firefox */
 .exaremoveScollBar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
 }
.courses-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
}

      svg-img {
        height: 100vh;
        height: auto;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 20%;
        left: 25%;

      }
      .unAuthorized-header {
        font-size: 48px;
        margin: 0;
      }
      .auathorized-p {
        font-size: 16px;
        width: 35%; 
        margin: 16px auto 24px;
        text-align: center;
      }
      .unAuthorized-btn {
        border-radius: 50px;
        padding: 8px 24px;
        font-size: 16px;
        cursor: pointer;
        background: #316dd4;
        color: #fff;
        border: none;
        box-shadow: 0 4px 8px 0 #ccc;
      }
      
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'PT Sans', sans-serif;
}

.hero {
   background: url(/static/media/113180-types-of-events.3f809336.gif) center/contain no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   position: relative;
}

.get-app {
   position: absolute;
   top: 0;
   right: 0;
   padding: 1rem 2rem;
   display: flex;
   justify-content: center;
}
