
      svg-img {
        height: 100vh;
        height: auto;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 20%;
        left: 25%;

      }
      .unAuthorized-header {
        font-size: 48px;
        margin: 0;
      }
      .auathorized-p {
        font-size: 16px;
        width: 35%; 
        margin: 16px auto 24px;
        text-align: center;
      }
      .unAuthorized-btn {
        border-radius: 50px;
        padding: 8px 24px;
        font-size: 16px;
        cursor: pointer;
        background: #316dd4;
        color: #fff;
        border: none;
        box-shadow: 0 4px 8px 0 #ccc;
      }
      